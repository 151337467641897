import { apiLinks } from "../apiCalls";
import { _get } from "../axios.config";
import { AuthActionTypes } from "../constants/Auth-types";
import { landLordTypes } from "../constants/Landlord";
import { error } from "../../component/Toaster";

export const getLandlords = () => {
    return async function (dispatch, getState) {
        try {
            const response = await _get(apiLinks.getLandlords);            
            if (response.status) {                
                dispatch({ type: landLordTypes.GET_LANDLORD_LIST, payload: response.data })
            } else {
                dispatch({ type: landLordTypes.GET_LANDLORD_LIST_ERROR })
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}