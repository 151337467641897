import { AuthActionTypes } from "../constants/Auth-types";

const initialState = {
    isLoggedIn: false,
    apiToken: null,
    message: null
}

export const Auth = (state, action) => {
    const { type, payload } = action;    
    switch (type) {
        case AuthActionTypes.LOGIN:
            return { ...state, isLoggedIn: payload.status };
        case AuthActionTypes.LOGIN_ERROR:
            return { ...state, isLoggedIn: payload.status, message: payload.message };
        case AuthActionTypes.LOGOUT:
            return { ...state, isLoggedIn: payload.isLoggedIn };
        case AuthActionTypes.SET_LOGIN:
            return { ...state, isLoggedIn: payload.isLoggedIn };
        default:
            return initialState
    }
}