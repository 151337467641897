import { landLordTypes } from "../constants/Landlord";

const initialState = {
    landlords: []
}

export const Landlord = (state, action) => {
    const { type, payload } = action;    
    switch (type) {
        case landLordTypes.GET_LANDLORD_LIST:
            return { ...state, landlords: payload.landlords };
        case landLordTypes.GET_LANDLORD_LIST_ERROR:
            return { ...state, landlords: [] };
        default:
            return initialState
    }
}