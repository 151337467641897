import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProperties } from "../../redux/actions/Property";
import { DataTable } from "../../component/DataTable";
import { Loader } from "../../component/Loader";

import "./style.css"
import { useState } from 'react';
import { Paper } from '@material-ui/core';


export function Property() {
    let dispatch = useDispatch();
    let propertyState = useSelector((state) => state.Property)

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(true)

    function nameSort(first, second, key) {
        return first.landlord?.user[key] > second.landlord?.user[key] ? 1 : -1
    }

    const columns = [
        {
            title: 'Landlord First Name',
            field: 'first_name',
            render: rowData => `${rowData?.landlord?.user?.first_name}`,
            customFilterAndSearch: (term, rowData) => rowData?.landlord?.user?.first_name.match(new RegExp(term, 'i')),
            customSort: (a, b) => nameSort(a, b, 'first_name')
        },
        {
            title: 'Landlord Last Name',
            field: 'last_name',
            render: rowData => `${rowData?.landlord?.user?.last_name}`,
            customFilterAndSearch: (term, rowData) => rowData?.landlord?.user?.last_name.match(new RegExp(term, 'i')),
            customSort: (a, b) => nameSort(a, b, 'last_name')
        },
        { title: 'Address line 1', field: 'address_line1' },
        { title: 'Address line 2', field: 'address_line2' },
        { title: 'Postcode', field: 'postcode' },
    ];

    const options = {
        filtering: true,
        grouping: true
    }

    useEffect(() => {        
        dispatch(getProperties());
    }, []);

    useEffect(() => {
        setData(propertyState.properties)
        setOpen(false);
    }, [propertyState.properties])    
    return (
        <div>
            <Loader open={open} />
            <Paper elevation={3} >
                <DataTable
                    title="Property"
                    columns={columns}
                    data={data}
                    action={null}
                    options={options}
                />
            </Paper>
        </div>
    )
}