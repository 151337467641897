import React from 'react';
import ReactDOM from 'react-dom';
import { Layout } from './view/Layout';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from "./redux/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} >
      <Layout />      
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
