import { UserTypes } from "../constants/User";

const initialState = {
    profile: null
}

export const User = (state, action) => {
    const { type, payload } = action;        
    switch (type) {
        case UserTypes.GET_PROFILE:
            return { ...state, profile: payload.userDetails };
        case UserTypes.GET_PROFILE_ERROR:
            return { ...state, profile: null };
        default:
            return initialState
    }
}