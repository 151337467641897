import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { login } from "../../redux/actions/Auth";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Card, CardContent } from '@material-ui/core';

import { Typography } from '@mui/material';

import "./style.css";


export function Login(props) {
    const dispatch = useDispatch();
    const { Auth } = useSelector((state) => state);

    const [username, setUsername] = useState('shahad@openbrix.co.uk');
    const [password, setPassword] = useState('IyNbRAI');

    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate(path);
    }

    function submit() {
        validation();
        if (!(usernameError && passwordError)) {
            dispatch(login({ email: username, password }));
        }
    }

    function validation() {
        if (username.trim() == "") {
            setUsernameError(true)
        }
        if (username.trim() == "") {
            setPasswordError(true)
        }
    }

    

    useEffect(() => {
        if (Auth && Auth.isLoggedIn) {
            routeChange('dashboard');
            window.location.reload(false);
        } 
    }, [Auth])

    return (
        <Grid container spacing={2} >
            <Grid item xs={6} className="center-me leftDiv">
                <div>
                    <img src="https://www.hubita.co.uk/assets/front/images/Hubita-logo.png" height={200} />
                </div>
            </Grid>
            <Grid item xs={6} className="center-me rightDiv">
                <Card >
                    <CardContent>
                        <Box>
                            <form className='form'>
                                <div className='insideForm'>
                                    <Typography variant="h6" gutterBottom component="div" color={"#483DD2"}>
                                        Hubita Admin Login
                                    </Typography>
                                </div>
                                <div className='insideForm'>
                                    <TextField id="outlined-basic" label="Username" variant="outlined" onChange={(event) => setUsername(event.target.value)}
                                        className="inputField"
                                        error={usernameError}
                                        helperText={usernameError ? "Username can not be empty" : ''}
                                    />
                                </div>
                                <div className='insideForm'>
                                    <TextField
                                        type="password"
                                        id="outlined-basic" label="Password" variant="outlined" onChange={(event) => setPassword(event.target.value)}
                                        className="inputField"
                                        error={passwordError}
                                        helperText={passwordError ? "Password can not be empty" : ''}
                                    />
                                </div>
                                <div className='insideForm'>
                                    <Button variant="outlined" onClick={submit} style={{ float: 'right' }}>Submit</Button>
                                </div>
                            </form>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}