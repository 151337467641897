import React from "react";
import { Grid } from "@material-ui/core";
import { Typography } from '@mui/material';


export function ComingSoon() {
    return (
        <Grid container spacing={2} className="center-me leftDiv">
            <Typography variant="h3" gutterBottom component="div" color={"#483DD2"}>
                Coming soon ...
            </Typography>
        </Grid>
    )
}