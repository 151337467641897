import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, FormControl, Typography, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Dropzone from 'react-dropzone'
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import { warning } from "../../component/Toaster";
import { getMediaById, resetCreatePost, updateMediaById } from "../../redux/actions/MediaHub";
import { useDispatch, useSelector } from 'react-redux';
import { apiLinks } from '../../redux/apiCalls';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
    paper: {
        width: '100%',
        height: '100vh'
    },
    h6: {
        fontSize: '1.25rem',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans - serif'",
        fontWeight: '500',
        lineHeight: '1.6',
        letterSpacing: '0.0075em',
        padding: '20px 0px 0px 30px'
    },
    rightButton: {
        padding: '23px 30px 0px 0px'
    },
    div: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    formControl: {
        minWidth: '100%',
        padding: '0% 3% 3% 3%'
    },
    container: {
        width: '100%',
        height: '20%',
        border: '1px solid black'
    },
    img: {
        height: '100px',
        width: '150px',
    }
}));

export function EditMediaHub(props) {
    const classes = useStyles();

    const { id, type } = useParams();

    let [description, setDescription] = useState('');
    let [thumb, setThumb] = useState(null);
    let [fileToUpload, setFileToUpload] = useState([]);
    let [title, setTitle] = useState('');
    let [link, setLink] = useState('');
    let [mediaId, setMediaId] = useState(id);
    let [isHideUpload, setHideUpload] = useState(!(type === 'NEWS' || type === 'BLOG'));
    let [videoType, setVideoType] = useState("YOUTUBE");
    let [videoId, setVideoId] = useState('');

    let dispatch = useDispatch();
    let CreateMediaState = useSelector((state) => state.MediaHub);
    let navigate = useNavigate();

    useEffect(() => {
        if (CreateMediaState.postAdded) {
            navigate(`/media-hub/${type}`);
            dispatch(resetCreatePost());
        }
    })

    useEffect(() => {
        dispatch(getMediaById(id))
    }, [])

    useEffect(() => {
        setTitle(CreateMediaState?.individualMediaData?.title);
        setDescription(CreateMediaState?.individualMediaData?.description);
        if (type != "VIDEO") {
            setLink(CreateMediaState?.individualMediaData?.link);
            setThumb(`${apiLinks.getAssets}/${CreateMediaState?.individualMediaData?.file}`)
        } else {
            setVideoType(CreateMediaState?.individualMediaData?.video_type);
            setVideoId(CreateMediaState?.individualMediaData?.video_id);
        }
    }, [CreateMediaState.individualMediaData])

    function onDropImages(data) {
        const thumb = Object.assign({}, { preview: URL.createObjectURL(data[0]) });
        setThumb(thumb)
        const newImages = data.map((file) =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
        );
        setThumb(newImages[0].preview);
        setFileToUpload(newImages)
    }

    function submit() {
        if (!(title && title.trim())) {
            warning("Title is required");
        }
        // console.log('title : ', title);
        // console.log('desc : ', description);
        // console.log('link : ', link);
        // console.log('upload : ', fileToUpload);
        // console.log('upload : ', id);
        dispatch(updateMediaById({ title, description, link, fileToUpload, type, id, videoType, videoId }))
    }

    function cancel() {
        navigate(`/media-hub/${type}`);
    }

    return (
        <div className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <div className={classes.div}>
                    <div>
                        <Typography variant="h6" gutterBottom className={classes.h6}>
                            {type}
                        </Typography>
                    </div>
                </div>

                <FormControl className={classes.formControl} >
                    <TextField id="outlined-basic" label="Title" variant="outlined"
                        name='title'
                        value={title}
                        onChange={(event) => setTitle(event.target.value)} />
                </FormControl>

                <FormControl className={classes.formControl} >
                    <ReactQuill
                        name='description'
                        value={description || ""}
                        onChange={(content) => setDescription(content)}
                    />
                </FormControl>
                {
                    isHideUpload ? (
                        <React.Fragment>
                            <FormControl className={classes.formControl} >
                                <FormLabel component="legend">Video Type</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" value={videoType} onChange={(event) => setVideoType(event.target.value)}>
                                    <FormControlLabel value="YOUTUBE" control={<Radio />} label="Youtube" />
                                    <FormControlLabel value="VIMEO" control={<Radio />} label="Vimeo" />
                                </RadioGroup>
                            </FormControl>
                            <FormControl className={classes.formControl} >
                                <TextField id="outlined-basic" label="Video Id" variant="outlined"
                                    name='video_id'
                                    value={videoId}
                                    onChange={(event) => setVideoId(event.target.value)} />
                            </FormControl>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <FormControl className={classes.formControl} >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    border: '1px solid black'
                                }}>
                                    <Dropzone multiple={false}
                                        onDrop={(acceptedFiles) =>
                                            onDropImages(acceptedFiles)
                                        }>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                                {
                                    thumb ? <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        margin: '3% 0 0 0'
                                    }}>
                                        <img className={classes.img} src={thumb} />
                                    </div> : null
                                }
                            </FormControl>
                            <FormControl className={classes.formControl} >
                                <TextField id="outlined-basic" label="Link" variant="outlined"
                                    name='link'
                                    value={link}
                                    onChange={(event) => setLink(event.target.value)}
                                />
                            </FormControl>
                        </React.Fragment>
                    )
                }
                <div style={{
                    width: '100 %',
                    padding: '0px 3% 3% 3%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}>
                    <Button variant="contained" color="primary" type='button' onClick={submit}
                        style={{ margin: '0px 3% 0px 0px' }}
                    >
                        Submit
                    </Button>
                    <Button variant="contained" color="secondary" type='button' onClick={cancel}
                        style={{ margin: '0px 3% 0px 0px' }}
                    >
                        Cancel
                    </Button>
                </div>
            </Paper >
        </div >
    )
}