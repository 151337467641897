import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button, Dialog, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from '../../component/DataTable';
import { getPaymentTypes, createPaymentType, deletePaymentTypes } from "../../redux/actions/Utility";
import { ConfirmDelete } from "../../component/DeleteConfirmDialog";
import { DynamicForm } from "../../component/DynamicForm";
import { warning } from "../../component/Toaster";
import { Loader } from '../../component/Loader';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
    paper: {
        width: '100%',
        height: '100vh',
    },
    div: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    h6: {
        fontSize: '1.25rem',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans - serif'",
        fontWeight: '500',
        lineHeight: '1.6',
        letterSpacing: '0.0075em',
        padding: '20px 0px 0px 30px'
    },
    rightButton: {
        padding: '23px 30px 0px 0px'
    }
}));

export function PaymentType() {
    const classes = useStyles();
    let dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [createOpen, setCreateOpen] = React.useState(false);
    const [valueToDelete, setValueToDelete] = useState(null)
    const [open, setOpen] = useState(true)
    const paymentTypeState = useSelector((state) => state.Utility);


    const formFields = [{
        placeHolder: 'Name',
        formControlName: 'name',
        formControlType: 'text',
    }];

    useEffect(() => {
        dispatch(getPaymentTypes())
    }, [])

    useEffect(() => {
        setData(paymentTypeState.paymentType)
        setOpen(false);
    }, [paymentTypeState.paymentType])

    const columns = [
        { title: 'Name', field: 'name', render: rowData => `${rowData?.name.toUpperCase()}`, }
    ];

    const options = {
        actionsColumnIndex: -1
    }

    const action = [
        {
            icon: 'delete',
            tooltip: 'Delete Reminder Type',
            onClick: (event, rowData) => { setDeleteOpen(true); setValueToDelete(rowData) }
        }
    ]

    function getDeleteDialogValue(agree, closeDialog) {
        if (agree) {
            dispatch(deletePaymentTypes(valueToDelete.id))
        }
        setDeleteOpen(!closeDialog);
    }

    function closeDynamicForm() {
        setCreateOpen(false);
    }

    function getFormValues(payload) {
        if (validation(payload)) {
            dispatch(createPaymentType(payload))
            closeDynamicForm();
        }
    }

    function validation(payload) {
        if (!payload.name) {
            warning("Name cannot be empty.")
            return false;
        }
        return true;
    }

    return (
        <div>
            <Loader open={open} />
            <div className={classes.root}>
                <Paper elevation={3} className={classes.paper}>
                    <div className={classes.div}>
                        <div>
                            <Typography variant="h6" gutterBottom className={classes.h6}>
                                Payment Types
                            </Typography>
                        </div>
                        <div className={classes.rightButton}>
                            <Button variant="contained" color="primary" onClick={() => setCreateOpen(true)}>
                                Create
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Paper elevation={0} >
                            <DataTable
                                title=""
                                columns={columns}
                                data={data}
                                action={action}
                                options={options}
                            />
                        </Paper>
                    </div>
                    <ConfirmDelete open={deleteOpen} getDialogValue={getDeleteDialogValue} valueToDelete={valueToDelete} />
                    <Dialog open={createOpen} onClose={closeDynamicForm} aria-labelledby="form-dialog-title">
                        <DynamicForm
                            formLabel={'Payment Type'}
                            getFormValues={getFormValues}
                            closeDynamicForm={closeDynamicForm}
                            formFields={formFields}
                        />
                    </Dialog>
                </Paper>
            </div>
        </div>
    )
}