export const Utilities = {
    GET_REMINDER_TYPE: 'GET_REMINDER_TYPE',
    REMINDER_TYPE_ERROR: 'REMINDER_TYPE_ERROR',
    
    GET_PAYMENT_STATUS: 'GET_PAYMENT_STATUS',
    PAYMENT_STATUS_ERROR: 'PAYMENT_STATUS_ERROR',

    GET_PAYMENT_TYPE: 'GET_PAYMENT_TYPE',
    PAYMENT_TYPE_ERROR: 'PAYMENT_TYPE_ERROR',

    GET_MORTGAGE_PROVIDER: 'GET_MORTGAGE_PROVIDER',
    MORTGAGE_PROVIDER_ERROR: 'MORTGAGE_PROVIDER_ERROR',

    GET_UTILITY_MASTER: 'GET_UTILITY_MASTER',
    GET_UTILITY_MASTER_ERROR: 'GET_UTILITY_MASTER_ERROR',

    GET_UTILITY_PROVIDER: 'GET_UTILITY_PROVIDER',
    GET_UTILITY_PROVIDER_ERROR: 'GET_UTILITY_PROVIDER_ERROR'
}