import { UserTypes } from "../constants/User";
import { AuthActionTypes } from "../constants/Auth-types";
import { _get, responseFormatter } from "../axios.config";
import { apiLinks } from "../apiCalls";
import { error } from "../../component/Toaster";


export const getProfile = () => {
    return async function (dispatch, getState) {
        try {
            const response = await _get(apiLinks.getProfile);            
            if (response.status) {
                dispatch({ type: UserTypes.GET_PROFILE, payload: response.data })
            } else {
                dispatch({ type: UserTypes.GET_PROFILE_ERROR })
                error(response.message)
            }
        } catch (err) {            
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}