import { apiLinks } from "../apiCalls";
import { _get } from "../axios.config";
import { AuthActionTypes } from "../constants/Auth-types";
import { propertyTypes } from "../constants/Property";
import { error } from "../../component/Toaster";
import { trackPromise } from 'react-promise-tracker';

export const getProperties = () => {
    return async function (dispatch, getState) {
        try {
            const response = await trackPromise(
                _get(apiLinks.properties)
            )   
            if (response.status) {
                dispatch({ type: propertyTypes.GET_PROPERTY_LIST, payload: response.data })
            } else {
                dispatch({ type: propertyTypes.GET_PROPERTY_LIST_ERROR })
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}