
import { Paper } from '@mui/material';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLandlords } from "../../redux/actions/Landlords";
import { DataTable } from "../../component/DataTable";

import "./style.css"
import { useState } from 'react';
import { Loader } from '../../component/Loader';

export function Landlord() {
    let dispatch = useDispatch();
    let landlordState = useSelector((state) => state.Landlord)
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(true)

    const columns = [
        { title: 'First Name', field: 'first_name' },
        { title: 'Last Name', field: 'last_name' },
        { title: 'Email', field: 'email' },
        { title: 'Phone', field: 'contact_number' },
    ];

    const options = {
        filtering: true,
        grouping: true
    }

    useEffect(() => {
        setOpen(true);
        dispatch(getLandlords());
    }, []);

    useEffect(() => {
        setData(landlordState.landlords)
        setOpen(false);
    }, [landlordState.landlords])

    return (
        <div>
            <Loader open={open} />
            <Paper elevation={3} >
                <DataTable
                    title="Landlords"
                    columns={columns}
                    data={data}
                    action={null}
                    options={options}
                />
            </Paper>
        </div>
    )
}