import MaterialTable from 'material-table';
import { tablepadding } from "./style.js";

export function DataTable(props) {
    const { title, columns, data, action, options, editable } = props;
    return (
        <div>
            <MaterialTable
                style={tablepadding}
                title={title}
                columns={columns}
                data={data}
                actions={action}
                options={options}
                editable={editable}
            />
        </div>
    )
}
