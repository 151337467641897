import { Button, TextField, Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Typography from '@material-ui/core/Typography';
import "./style.css";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export function DynamicForm(props) {
    const classes = useStyles();

    const { formLabel, getFormValues, closeDynamicForm, formFields } = props;
    const [formAction, setFormAction] = useState([]);
    const [payload, setPayload] = useState({});

    useEffect(() => {
        setFormAction(formFields)
    }, [formFields])

    function save() {
        getFormValues(payload)
    }

    function getTextValues(index, value, key) {
        setPayload({ ...payload, [key]: value })
        formFields[index].value = value;
        setFormAction(formFields)
    }

    function getDropDownValues(index, value, key) {
        setPayload({ ...payload, [key]: value })                
        formFields[index].value = value;
        setFormAction(formFields)
    }

    function close() {
        closeDynamicForm()
    }

    return (
        <div className="form">
            <div className="formHeader">
                <Typography variant="h6" gutterBottom>
                    {formLabel}
                </Typography>
            </div>
            <div >
                {
                    formAction && formAction.length ? formAction.map((row, index) => {
                        if (row.formControlType === 'text') {
                            return (
                                <FormControl className={classes.formControl} key={row?.placeHolder}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label={row?.placeHolder}
                                        type={row?.formControlType}
                                        onChange={(event) => getTextValues(index, event.target.value, row.formControlName)}
                                        fullWidth
                                    />
                                </FormControl>
                            )
                        }
                        if (row.formControlType === 'dropdown') {
                            return (
                                <FormControl className={classes.formControl} key={row?.placeHolder}>
                                    <InputLabel id="demo-simple-select-label">{row.placeHolder}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={row.value}
                                        onChange={(event) => getDropDownValues(index, event.target.value, row.formControlName)}
                                    >
                                        {
                                            row.options.map(selectRow => (
                                                <MenuItem value={selectRow.id} key={selectRow.id}>
                                                    {selectRow.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            )
                        }
                    }) : null
                }
            </div>
            <div className="formButton">
                <Button variant="contained" color="secondary" onClick={close} style={{ marginRight: '10px' }}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={save}>
                    Submit
                </Button>
            </div>
        </div>
    )
}