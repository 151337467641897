import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProfile } from "../../redux/actions/User";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { MailOutline, PermIdentity } from '@material-ui/icons';

import Userprofile from "./ProfileDialog";
import { DynamicForm } from "../../component/DynamicForm";
import { warning } from "../../component/Toaster";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    profilePaper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '150px'
    },
    feedPaper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        height: '100vh'
    },
}));

export function Dashboard() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const User = useSelector((state) => state.User)
    const [profile, setProfile] = useState(null)

    const [open, setOpen] = React.useState(false);
    
    const handleClickOpenProfile = () => {
        setOpen(true);
    };

    const formFields = [{
        placeHolder: 'First Name',
        formControlName: 'first_name',
        formControlType: 'text',
        value: ''
    }, {
        placeHolder: 'Last Name',
        formControlName: 'last_name',
        formControlType: 'text',
        value: ''
    }, {
        placeHolder: 'Email',
        formControlName: 'email',
        formControlType: 'text',
        value: ''
    }, {
        placeHolder: 'Contact Number',
        formControlName: 'contact_number',
        formControlType: 'text',
        value: ''
    }];

    useEffect(() => {
        dispatch(getProfile());
    }, [])

    useEffect(() => {
        setProfile(User.profile)        
    }, [User.profile])

    function closeDynamicForm() {
        setOpen(false);
    }

    function getFormValues(payload) {
        if (validation(payload)) {                        
            closeDynamicForm();
        }
    }

    function validation(payload) {
        if (!payload.first_name) {
            warning("First Name cannot be empty.")
            return false;
        } else if (!payload.last_name) {
            warning("Last Name cannot be empty.")
            return false;
        } else if (!payload.email) {
            warning("Email cannot be empty.")
            return false;
        }
        return true;
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.profilePaper}>
                        <Grid container spacing={3}>
                            <Grid item xs={11}>
                                <List>
                                    <ListItem >
                                        <ListItemIcon>{<PermIdentity />}</ListItemIcon>
                                        <ListItemText primary={`${profile?.first_name} ${profile?.last_name}`} />
                                    </ListItem>
                                    <ListItem >
                                        <ListItemIcon>{<MailOutline />}</ListItemIcon>
                                        <ListItemText primary={profile?.email} />
                                    </ListItem>
                                </List>
                            </Grid>
                            {/* <Grid item xs={1}>
                                <List>
                                    <ListItem button onClick={handleClickOpenProfile} >
                                        <ListItemIcon>{<Edit />}</ListItemIcon>
                                    </ListItem>
                                </List>
                            </Grid> */}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={4} >
                    <Paper className={classes.feedPaper}>
                        <Typography variant="h6" gutterBottom>
                            Welcome to Hubita
                        </Typography>
                        <Divider />
                    </Paper>
                </Grid>
                <Grid item xs={4} >
                    <Paper className={classes.feedPaper}>
                        <Typography variant="h6" gutterBottom>
                            Community Channel News
                        </Typography>
                        <Divider />
                    </Paper>
                </Grid>
                <Grid item xs={4} >
                    <Paper className={classes.feedPaper}>
                        <Typography variant="h6" gutterBottom>
                            Hubita News
                        </Typography>
                        <Divider />
                    </Paper>
                </Grid>
            </Grid>
            {/* <Dialog open={open} onClose={closeDynamicForm} aria-labelledby="form-dialog-title">
                <DynamicForm formLabel={'User Profile'} formFields={formFields} getFormValues={getFormValues}
                    closeDynamicForm={closeDynamicForm} />
            </Dialog> */}
        </div>
    )
}