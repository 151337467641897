import { apiLinks } from "../apiCalls";
import { _delete, _get, _post, _put } from "../axios.config";
import { mediaHub } from "../constants/MediaHub";
import { error, success } from "../../component/Toaster";
import formdata from "form-data";
import { AuthActionTypes } from "../constants/Auth-types";



export const createPost = (payload) => {
    return async function (dispatch, getState) {
        try {
            var formData = new formdata();
            const { fileToUpload, type, ...rest } = payload;
            let assetsUploaded;
            formData.append("type", type);
            if (fileToUpload.length) {
                fileToUpload.forEach((item) => {
                    formData.append("files", item);
                });
                assetsUploaded = await _post(apiLinks.assetsUpload, formData);
            }


            const response = await _post(apiLinks.uploadPost, {
                ...rest,
                video_type: rest.videoType,
                video_id: rest.videoId,
                media_type: type,
                file: type === "VIDEO" ? "" : assetsUploaded?.data[0]?.openbrixName
            });
            if (response.status) {
                dispatch({ type: mediaHub.ADD_POST, payload: { success: true } });
                resetCreatePost();
            } else {
                dispatch({ type: mediaHub.ADD_POST_ERROR, payload: { success: false } });
                error(response.message)
            }
        } catch (err) {
            // console.log(err)
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const resetCreatePost = () => {
    return async function (dispatch, getState) {
        dispatch({ type: mediaHub.ADD_POST, payload: { success: false } });
    }
}

export const getMediaByType = (type) => {
    return async function (dispatch, getState) {
        try {
            const response = await _get(`${apiLinks.getMediaByType}?type=${type}`);
            // console.log('response : ', response);
            if (response.status) {
                dispatch({ type: mediaHub.GET_NEWS, payload: response });
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const getMediaById = (id) => {
    return async function (dispatch, getState) {
        try {
            const replacedUrl = apiLinks.getMediaById.replace("postId", id);
            const response = await _get(replacedUrl);
            if (response.status) {
                dispatch({ type: mediaHub.GET_INDIVIDUAL_POST, payload: response });
            }
        } catch (err) {
            // console.log(err)
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const updateMediaById = (payload) => {
    return async function (dispatch, getState) {
        try {
            var formData = new formdata();
            const { id, fileToUpload, type, ...rest } = payload;
            const replacedUrl = apiLinks.updateMediaById.replace("postId", id);
            let assetsUploaded;
            let finalPayload = { ...rest };
            if (fileToUpload.length) {
                formData.append("type", type);
                fileToUpload.forEach((item) => {
                    formData.append("files", item);
                });
                assetsUploaded = await _post(apiLinks.assetsUpload, formData);
                finalPayload.media_type = type;
                finalPayload.file = assetsUploaded.data[0].openbrixName;
            }
            finalPayload.video_type = rest.videoType;
            finalPayload.video_id = rest.videoId;
            finalPayload.media_type = type

            const response = await _put(replacedUrl, finalPayload);
            if (response.status) {
                dispatch({ type: mediaHub.ADD_POST, payload: { success: true } });
            } else {
                dispatch({ type: mediaHub.ADD_POST_ERROR, payload: { success: false } });
                error(response.message)
            }
        } catch (err) {
            // console.log(err)
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const deleteMediaById = (id, type) => {
    return async function (dispatch, getState) {
        try {
            const replacedUrl = apiLinks.deleteMediaById.replace("postId", id);
            const response = await _delete(replacedUrl);
            if (response.status) {
                dispatch(getMediaByType(type));
            } else {
                error(response.message)
            }
        } catch (err) {
            // console.log(err)
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}
