import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button, Typography } from '@material-ui/core';
import { DataTable } from '../../component/DataTable';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMediaById, getMediaByType } from '../../redux/actions/MediaHub';
import { apiLinks } from "../../redux/apiCalls";
import { ConfirmDelete } from '../../component/DeleteConfirmDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
    paper: {
        width: '100%',
        height: '100vh'
    },
    h6: {
        fontSize: '1.25rem',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans - serif'",
        fontWeight: '500',
        lineHeight: '1.6',
        letterSpacing: '0.0075em',
        padding: '20px 0px 0px 30px'
    },
    rightButton: {
        padding: '23px 30px 0px 0px'
    },
    div: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
}));

export function News(props) {

    const [data, setData] = useState([]);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [valueToDelete, setValueToDelete] = useState(null)

    const classes = useStyles();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let MediaState = useSelector((state) => state.MediaHub);
    // console.log(MediaState)
    useEffect(() => {
        dispatch(getMediaByType("NEWS"))
    }, [])

    useEffect(() => {
        setData(MediaState.mediaData);
    }, [MediaState.mediaData]);

    const columns = [
        { title: 'Title', field: 'title' },
        {
            title: 'Image',
            field: 'image',
            render: rowData => <img src={`${apiLinks.getAssets}/${rowData.file}`} style={{ width: 50, borderRadius: '50%' }} />
        },
        {
            title: 'Link',
            field: 'link'
        }
    ];

    function getDeleteDialogValue(agree, closeDialog) {
        if (agree) {
            dispatch(deleteMediaById(valueToDelete.id, "NEWS"));
        }
        setDeleteOpen(!closeDialog);
    }

    const options = {
        filtering: false,
        actionsColumnIndex: -1
    }

    const action = [
        {
            icon: 'delete',
            tooltip: 'Delete News',
            onClick: (event, rowData) => {
                rowData.name = rowData.title;
                setDeleteOpen(true); setValueToDelete(rowData)
            }
        },
        {
            icon: 'edit',
            tooltip: 'Edit News',
            onClick: (event, rowData) => {
                navigate(`/media-hub/edit/NEWS/${rowData.id}`);
            }
        }
    ]

    function moveToCreate() {
        // move to create
        navigate('/media-hub/create/NEWS')
    }

    return (
        <div className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <div className={classes.div}>
                    <div>
                        <Typography variant="h6" gutterBottom className={classes.h6}>
                            News
                        </Typography>
                    </div>
                    <div className={classes.rightButton}>
                        <Button variant="contained" color="primary" onClick={moveToCreate}>
                            Create
                        </Button>
                    </div>
                </div>
                <div>
                    <Paper elevation={0} >
                        <DataTable
                            title=""
                            columns={columns}
                            data={data}
                            action={action}
                            options={options}
                        />
                    </Paper>
                </div>
                <ConfirmDelete open={deleteOpen} getDialogValue={getDeleteDialogValue} valueToDelete={valueToDelete} />
            </Paper>
        </div>
    )
}