import axios from "axios";

export async function _get(
    url,
    params = {}
) {
    const authHeader = setAuthToken();
    const headers = { 'content-type': 'application/json', ...authHeader };
    const httpResponse = await axios.get(url, { headers }, params)
    return responseFormatter(httpResponse)
}

export async function _post(
    url,
    payload,
    params = {}) {
    const authHeader = setAuthToken();
    const headers = { 'content-type': 'application/json', ...authHeader };
    const httpResponse = await axios.post(url, payload, { headers });
    return responseFormatter(httpResponse)
}

export async function _delete(
    url,
    payload,
    params = {}) {
    const authHeader = setAuthToken();
    const headers = { 'content-type': 'application/json', ...authHeader };
    const httpResponse = await axios.delete(url, { headers });
    return responseFormatter(httpResponse)
}

export async function _put(
    url,
    payload,
    params = {}) {
    const authHeader = setAuthToken();
    const headers = { 'content-type': 'application/json', ...authHeader };
    const httpResponse = await axios.put(url, payload, { headers });
    return responseFormatter(httpResponse)
}

export function checkToken() {
    const token = localStorage.getItem('apiToken');
    if (token) {
        return true
    } else {
        return false
    }
}

function setAuthToken() {
    const token = localStorage.getItem('apiToken');
    const AuthorizationHeader = {}
    if (token) {
        AuthorizationHeader['Authorization'] = token && token.length ? `Bearer ${token}` : '';
    }
    return AuthorizationHeader
}

export function responseFormatter(response) {
    const { data, status } = response;
    // console.log("respnse  ", response)
    if (status === 200 && data.status) {
        return {
            status: data.status,
            data: data.payload ? data.payload : data.data
        }
    } else {
        return {
            status: data.status,
            message: data.message
        }
    }
}