import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
const defaultSetting = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export function success(message) {
    toast.success(message, defaultSetting)
}
export function warning(message) {
    toast.warn(message, defaultSetting)
}
export function info(message) {
    toast.info(message, defaultSetting)
}
export function error(message) {
    toast.error(message, defaultSetting)
}

