import { mediaHub } from "../constants/MediaHub";

const initialState = {
    postAdded: false,
    mediaData: [],
    individualMediaData: null
}

export const MediaHub = (state, action) => {
    const { type, payload } = action;
    // console.log("postAdded: payload.success: ", payload)
    switch (type) {
        case mediaHub.ADD_POST:
            return { ...state, postAdded: payload.success };
        case mediaHub.ADD_POST_ERROR:
            return { ...state, postAdded: payload.success };
        case mediaHub.GET_NEWS:
            return { ...state, mediaData: payload.data };
        case mediaHub.GET_INDIVIDUAL_POST:
            return { ...state, individualMediaData: payload.data };
        default:
            return initialState
    }
}