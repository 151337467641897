import { propertyTypes } from "../constants/Property";

const initialState = {
    properties: []
}

export const Property = (state, action) => {
    const { type, payload } = action;    
    switch (type) {
        case propertyTypes.GET_PROPERTY_LIST:            
            return { ...state, properties: payload.properties };
        case propertyTypes.GET_PROPERTY_LIST_ERROR:
            return { ...state, properties: [] };
        default:
            return initialState
    }
}