import { usePromiseTracker } from "react-promise-tracker";
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#FFFFFF',
    },
}));

export function Loader(props) {
    const { open } = props;
    const classes = useStyles();
    const { promiseInProgress } = usePromiseTracker();

    return (
        <Backdrop className={classes.backdrop} open={open} >            
            {/* <CircularProgress color="inherit" /> */}
            <img src="https://www.hubita.co.uk/assets/front/images/Hubita-logo.png" height={200} />
        </Backdrop>
    );

}

