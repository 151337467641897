import { apiLinks } from "../apiCalls";
import { _get, _delete, _post, _put } from "../axios.config";
import { AuthActionTypes } from "../constants/Auth-types";
import { Utilities } from "../constants/Utiliities";
import { error, success } from "../../component/Toaster";

/**Reminder Types */
export const getReminderTypes = () => {
    return async function (dispatch, getState) {
        try {
            const response = await _get(apiLinks.getReminderTypes);
            if (response.status) {
                dispatch({ type: Utilities.GET_REMINDER_TYPE, payload: response.data })
            } else {
                dispatch({ type: Utilities.REMINDER_TYPE_ERROR })
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const deleteReminderTypes = (id) => {
    return async function (dispatch, getState) {
        try {
            const updatedLink = apiLinks.deleteReminderType.replace('reminderTypeId', id);
            const response = await _delete(updatedLink);
            if (response.status) {
                success(response.data)
                dispatch(getReminderTypes())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const createReminderTypes = (payload) => {
    return async function (dispatch, getState) {
        try {
            const response = await _post(apiLinks.addReminderType, payload);
            if (response.status) {
                success(response.data)
                dispatch(getReminderTypes())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}


/**Payment Status */
export const getPaymentStatus = () => {
    return async function (dispatch, getState) {
        try {
            const response = await _get(apiLinks.getPaymentStatus);
            if (response.status) {
                dispatch({ type: Utilities.GET_PAYMENT_STATUS, payload: response.data })
            } else {
                dispatch({ type: Utilities.PAYMENT_STATUS_ERROR })
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const deletePaymentStatus = (id) => {
    return async function (dispatch, getState) {
        try {
            const updatedLink = apiLinks.deletePaymentStatus.replace('paymentStatusId', id);
            const response = await _delete(updatedLink);
            if (response.status) {
                success(response.data)
                dispatch(getPaymentStatus())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const createPaymentStatus = (payload) => {
    return async function (dispatch, getState) {
        try {
            const response = await _post(apiLinks.addPaymentStatus, payload);
            if (response.status) {
                success(response.data)
                dispatch(getPaymentStatus())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

/**Payment Type */
export const getPaymentTypes = () => {
    return async function (dispatch, getState) {
        try {
            const response = await _get(apiLinks.getPaymentType);
            if (response.status) {
                dispatch({ type: Utilities.GET_PAYMENT_TYPE, payload: response.data })
            } else {
                dispatch({ type: Utilities.PAYMENT_TYPE_ERROR })
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const deletePaymentTypes = (id) => {
    return async function (dispatch, getState) {
        try {
            const updatedLink = apiLinks.deletePaymentType.replace('paymentTypeId', id);
            const response = await _delete(updatedLink);
            if (response.status) {
                success(response.data)
                dispatch(getPaymentTypes())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const createPaymentType = (payload) => {
    return async function (dispatch, getState) {
        try {
            const response = await _post(apiLinks.addPaymentType, payload);
            if (response.status) {
                success(response.data)
                dispatch(getPaymentTypes())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

/**Mortgage Provider */
export const getMortgage = () => {
    return async function (dispatch, getState) {
        try {
            const response = await _get(apiLinks.getMortgage);            
            if (response.status) {
                dispatch({ type: Utilities.GET_MORTGAGE_PROVIDER, payload: response.data })
            } else {
                dispatch({ type: Utilities.PAYMENT_TYPE_ERROR })
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const deleteMortgageProvider = (id) => {
    return async function (dispatch, getState) {
        try {
            const updatedLink = apiLinks.deleteMortgage.replace('mortgageId', id);
            const response = await _delete(updatedLink);
            if (response.status) {
                success(response.data)
                dispatch(getMortgage())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const updateMortgageProvider = (payload) => {
    return async function (dispatch, getState) {
        try {
            const { id, ...rest } = payload;
            const updatedLink = apiLinks.updateMortgage.replace('mortgageId', id);
            const response = await _put(updatedLink, rest);
            if (response.status) {
                success(response.data)
                dispatch(getMortgage())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const createMortgage = (payload) => {
    return async function (dispatch, getState) {
        try {
            const response = await _post(apiLinks.addMortgage, payload);
            if (response.status) {
                success(response.data)
                dispatch(getMortgage())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

/**Utility Master */
export const getUtilityMaster = () => {
    return async function (dispatch, getState) {
        try {
            const response = await _get(apiLinks.getUtilityMasters);
            if (response.status) {
                dispatch({ type: Utilities.GET_UTILITY_MASTER, payload: response.data })
            } else {
                dispatch({ type: Utilities.GET_UTILITY_MASTER_ERROR })
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const deleteUtliltyMaster = (id) => {
    return async function (dispatch, getState) {
        try {
            const updatedLink = apiLinks.deleteUtilityMasters.replace('utilityMasterId', id);
            const response = await _delete(updatedLink);
            if (response.status) {
                success(response.data)
                dispatch(getUtilityMaster())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const updateUtilityMaster = (payload) => {
    return async function (dispatch, getState) {
        try {
            const { id, ...rest } = payload;
            const updatedLink = apiLinks.updateUtilityMasters.replace('utilityMasterId', id);
            const response = await _put(updatedLink, rest);            
            if (response.status) {
                success(response.data)
                dispatch(getUtilityMaster())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const createUtilityMaster = (payload) => {
    return async function (dispatch, getState) {
        try {
            const response = await _post(apiLinks.addUtilityMasters, payload);
            if (response.status) {
                dispatch(getUtilityMaster())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

/**Utility provider */
export const createUtilityProvider = (payload) => {
    return async function (dispatch, getState) {
        try {
            const response = await _post(apiLinks.addUtilityProviders, payload);
            if (response.status) {                
                dispatch(getUtilityProvider())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const getUtilityProvider = () => {
    return async function (dispatch, getState) {
        try {
            const response = await _get(apiLinks.getUtilityProviders);
            if (response.status) {
                dispatch({ type: Utilities.GET_UTILITY_PROVIDER, payload: response.data })
            } else {
                dispatch({ type: Utilities.GET_UTILITY_PROVIDER_ERROR })
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const updateUtilityProvider = (payload) => {
    return async function (dispatch, getState) {
        try {
            const { id, ...rest } = payload;

            const updatedLink = apiLinks.updateUtilityProviders.replace('utilityProviderId', id);
            const response = await _put(updatedLink, rest);
            if (response.status) {
                dispatch(getUtilityProvider())
            } else {
                dispatch({ type: Utilities.GET_UTILITY_PROVIDER_ERROR })
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const deleteUtliltyProvider = (id) => {
    return async function (dispatch, getState) {
        try {
            const updatedLink = apiLinks.deleteUtilityProviders.replace('utilityProviderId', id);
            const response = await _delete(updatedLink);
            if (response.status) {
                success(response.data)
                dispatch(getUtilityProvider())
            } else {
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}