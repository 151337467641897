import { Utilities } from "../constants/Utiliities";

const initialState = {
    reminderTypes: [],
    paymentStatus: [],
    paymentType: [],
    mortgageProvider: [],
    utilityMaster: [],
    utilityProviders: []
}

export const Utility = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case Utilities.GET_REMINDER_TYPE:
            return { ...state, reminderTypes: payload.reminderTypes };
        case Utilities.GET_PAYMENT_STATUS:
            return { ...state, paymentStatus: payload.paymentStatusTypes };
        case Utilities.GET_PAYMENT_TYPE:
            return { ...state, paymentType: payload.paymentTypes };
        case Utilities.GET_MORTGAGE_PROVIDER:
            return { ...state, mortgageProvider: payload };
        case Utilities.GET_UTILITY_MASTER:
            return { ...state, utilityMaster: payload.utilityProviders };
        case Utilities.GET_UTILITY_PROVIDER:
            return { ...state, utilityProviders: payload.utilityProviderDetails };
        default:
            return initialState
    }
}