import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { checkToken } from '../../redux/axios.config';


export function PublicOutlet(props) {
    const [isTokenSet, setIsToken] = useState(false)

    useEffect(() => {        
        setIsToken(checkToken());
    }, [])

    return (
        !isTokenSet ? < Outlet /> : <Navigate to="/dashboard" />
    )
}