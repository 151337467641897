import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button, Dialog, Typography } from '@material-ui/core';
import { DataTable } from '../../component/DataTable';
import { ConfirmDelete } from "../../component/DeleteConfirmDialog";
import { DynamicForm } from "../../component/DynamicForm";
import { warning } from "../../component/Toaster";
import { Loader } from '../../component/Loader';
import { getUtilityMaster, createUtilityProvider, getUtilityProvider, updateUtilityProvider, deleteUtliltyProvider } from "../../redux/actions/Utility";
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
    paper: {
        width: '100%',
        height: '100vh',
    },
    div: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    h6: {
        fontSize: '1.25rem',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans - serif'",
        fontWeight: '500',
        lineHeight: '1.6',
        letterSpacing: '0.0075em',
        padding: '20px 0px 0px 30px'
    },
    rightButton: {
        padding: '23px 30px 0px 0px'
    }
}));

export function UtilityProvider() {
    const classes = useStyles();
    const [data, setData] = useState([]);
    let dispatch = useDispatch();
    const utilityState = useSelector((state) => state.Utility);

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [valueToDelete, setValueToDelete] = useState(null)
    const [createOpen, setCreateOpen] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [utilityMaster, setUtilityMaster] = useState([]);
    const [utilityMasterLookup, setUtilityMasterLookup] = useState(null);

    useEffect(() => {
        dispatch(getUtilityMaster())
        dispatch(getUtilityProvider())
    }, [])

    useEffect(() => {
        const lookupObject = {};
        if (utilityState?.utilityMaster.length) {
            utilityState?.utilityMaster.forEach((row, index) => {
                lookupObject[row.id] = row.name
            })
        }
        setUtilityMasterLookup(lookupObject);
        setUtilityMaster(utilityState.utilityMaster);

        setData(utilityState.utilityProviders)
        setOpen(false);
    }, [utilityState.utilityMaster, utilityState.utilityProviders])

    const formFields = [{
        placeHolder: 'Name',
        formControlName: 'name',
        formControlType: 'text',
        value: ''
    }, {
        placeHolder: 'Address',
        formControlName: 'address',
        formControlType: 'text',
        value: ''
    }, {
        placeHolder: 'Post Code',
        formControlName: 'postcode',
        formControlType: 'text',
        value: ''
    }, {
        placeHolder: 'Phone',
        formControlName: 'phone',
        formControlType: 'text',
        value: ''
    }, {
        placeHolder: 'Website',
        formControlName: 'website',
        formControlType: 'text',
        value: ''
    }, {
        placeHolder: 'Utility Type',
        formControlName: 'type',
        formControlType: 'dropdown',
        options: utilityMaster,
        value: ''
    }];

    const columns = [
        {
            title: 'Name', field: 'name', isFreeAction: true,
        },
        { title: 'Address', field: 'address' },
        { title: 'Post Code', field: 'postcode' },
        { title: 'Phone', field: 'phone' },
        { title: 'Website', field: 'website' },
        {
            title: 'Type',
            field: 'type',
            lookup: utilityMasterLookup,
            render: rowData => `${rowData?.utility_provider_master?.name}`,
            customFilterAndSearch: (term, rowData) => dropDownFilter(term, rowData),
            customSort: (a, b) => nameSort(a, b, 'type')
        }
    ];

    function dropDownFilter(term, rowData) {
        if (Array.isArray(term) && term.length) {
            return term.map(id => Number(id)).includes(rowData?.utility_provider_master?.id)
        } else if (term != "") {
            return rowData;
        } else {
            return rowData
        }
    }

    function nameSort(first, second, key) {
        return first.utility_provider_master?.id > second.utility_provider_master?.id ? 1 : -1
    }

    const options = {
        filtering: true,
        actionsColumnIndex: -1
    }

    const editable = {
        onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {                
                resolve(dispatch(updateUtilityProvider(newData)));
            })
    }

    const action = [
        {
            icon: 'delete',
            tooltip: 'Delete Utility Provider',
            onClick: (event, rowData) => { setDeleteOpen(true); setValueToDelete(rowData) }
        }
    ]

    function closeDynamicForm() {
        setCreateOpen(false);
    }

    function getFormValues(payload) {
        if (validation(payload)) {            
            dispatch(createUtilityProvider(payload))
            closeDynamicForm();
        }
    }

    function validation(payload) {
        let flag = true;
        if (Object.keys(payload).length) {
            const formFieldsKeys = formFields.map((row) => row.formControlName);
            formFieldsKeys.forEach((row) => {
                if (payload.hasOwnProperty(row)) {
                    if (!payload[row]) {
                        warning(`${row} can not be empty`)
                        flag = false;                        
                    }
                } else {
                    warning(`${row} can not be empty`)
                    flag = false;                    
                }
            });
        } else if (Object.keys(payload).length == 0) {
            flag = false;
            warning(`Form fields can not be empty`)
        }
        return flag;
    }

    function getDeleteDialogValue(agree, closeDialog) {
        if (agree) {            
            dispatch(deleteUtliltyProvider(valueToDelete.id))
        }
        setDeleteOpen(!closeDialog);
    }

    return (
        <div>
            <Loader open={open} />
            <div className={classes.root}>
                <Paper elevation={3} className={classes.paper}>
                    <div className={classes.div}>
                        <div>
                            <Typography variant="h6" gutterBottom className={classes.h6}>
                                Utility Provider
                            </Typography>
                        </div>
                        <div className={classes.rightButton}>
                            <Button variant="contained" color="primary" onClick={() => setCreateOpen(true)}>
                                Create
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Paper elevation={0} >
                            <DataTable
                                title=""
                                columns={columns}
                                data={data}
                                action={action}
                                options={options}
                                editable={editable}
                            />
                        </Paper>
                    </div>
                    <ConfirmDelete open={deleteOpen} getDialogValue={getDeleteDialogValue} valueToDelete={valueToDelete} />
                    <Dialog open={createOpen} onClose={closeDynamicForm} aria-labelledby="form-dialog-title">
                        <DynamicForm
                            formLabel={'Create Utility Provider'}
                            getFormValues={getFormValues}
                            closeDynamicForm={closeDynamicForm}
                            formFields={formFields}
                        />
                    </Dialog>
                </Paper>
            </div>
        </div>
    )
}