import { AuthActionTypes } from "../constants/Auth-types";

import { _post } from "../axios.config";
import { apiLinks } from "../apiCalls";
import { error } from "../../component/Toaster";

export const login = (payload) => {    
    return async function (dispatch, getState) {
        try {
            let response = await _post(apiLinks.login, payload);            
            if (response.status) {                
                localStorage.setItem('apiToken', response.data.token )
                dispatch({ type: AuthActionTypes.LOGIN, payload: response })
            } else {                
                dispatch({ type: AuthActionTypes.LOGIN_ERROR, payload: response })
                error(response.message)
            }
        } catch (err) {            
            // dispatch({ type: AxiosActionTypes.NETWORK_ERROR, payload: err.message })
            error(err.message)
        }
    }
}

export const logoutUser = () => {
    return async function (dispatch, getState) {
        localStorage.removeItem('apiToken');
        dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
    }
}

export const setLoggedInFlag = () => {
    return async function (dispatch, getState) {
        dispatch({ type: AuthActionTypes.SET_LOGIN, payload: { isLoggedIn: true } })
    }
}