export const mediaHub = {
    ADD_POST: 'ADD_POST',
    ADD_POST_ERROR: 'ADD_POST_ERROR',
    RESET_ADD_POST: 'RESET_ADD_POST', 

    GET_NEWS: 'GET_NEWS',
    GET_NEWS_ERROR: 'GET_NEWS_ERROR',

    GET_INDIVIDUAL_POST: 'GET_INDIVIDUAL_POST',
    GET_INDIVIDUAL_POST_ERROR: 'GET_INDIVIDUAL_POST_ERROR',

    DELETE_POST_ERROR: 'DELETE_POST_ERROR',
}